import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Box,
    IconButton,
    Grid
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import '../../custom.css';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSnackbar } from "notistack";
import { showLoader, hideLoader } from './Loader';

import api from "../../axios/axios";

export default function UploadComponent({ field = null, onEndUpload, formik=null, column=null}) {
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState(null);
    const [uploaded, setUploaded] = useState(false);
    const [validFile, setValidFile] = useState(false);
    const [typeFile, setTypeFile] = useState(null);

    const inputFileRef = useRef();

    const uploadFileNoProgress = async (fileSelect) => {
        const data = new FormData();
        data.append('file', fileSelect);

        try {
            var res = await api.post("/api/uploadFiles/uploadFile", data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                }
            });
            return res;
        } catch (error) {
            if (error?.response?.data) return error.response;
            return { data: { error: "Error al cargar el archivo" } }
        }
    }

    const onChangeHandler = async (event) => {
        try {
            if (event.target.files.length > 0) {
                var fileSelected = event.target.files[0];

                //Validate size of the file
                if (fileSelected.size < (300 * 1000 * 1024)) {
                    showLoader();
                    //Validación de cabeceras del archivo selecciondo    
                    //Se validan las pestañas disponibles
                    setValidFile(true);
                    setValue(fileSelected);
                    enqueueSnackbar("Archivo valido", { variant: "success" });
                    hideLoader();
                } else {
                    enqueueSnackbar("El archivo supera el tamaño permitido para el cargue, limite máximo 300MB", { variant: "error" });
                    inputFileRef.current.value = null;
                    setValue(null);
                }
            }
        } catch (e) {
            enqueueSnackbar("Error en la validación del archivo cargado, verifique el formato del archivo y su tamaño", { variant: "error" });
            hideLoader();
        }
    }

    const uploadFile = async () => {
        console.log("Start upload");
        if (value) {
            showLoader();
            var res = await uploadFileNoProgress(value);
            if (res.data.error) {
                hideLoader();
                enqueueSnackbar(res.data.error, { variant: "error" });
                
                setUploaded(false);
            } else {
                hideLoader();                
                enqueueSnackbar(res.data.message, { variant: "success" });
                inputFileRef.current.value = null;

                onEndUpload(res.data.url);
                //setValue(null);
                
                setUploaded(true);
            }
        }
    }

    useEffect(()=>{
        if(field){
            let extension = field.split("II")[1];
            console.log(extension);
            setTypeFile("." + extension);
        }
    },[])

    return (
        <>
            <>
                {value &&
                    <Box p={1}>
                        <Grid container className='panelContent'>
                            <Grid item xs={10}>
                                <Box className='paddingAlign'>
                                    {value?.name}
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box p={1} display="flex" justifyContent="flex-end">
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            inputFileRef.current.value = null;
                                            setValue(null);
                                            setUploaded(false);
                                        }} aria-label="Eliminar documento">
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {value &&  !uploaded &&
                    <Box p={1}>
                        <Button
                            disabled={!validFile}
                            fullWidth
                            color="info"
                            variant="contained"
                            endIcon={<SendIcon />}
                            onClick={() => {
                                uploadFile()
                            }}>
                            SUBIR ARCHIVO
                        </Button>
                    </Box>
                }
            </>
            {!value && !uploaded &&
                <Box p={1}>
                    <Button
                        fullWidth
                        color="info"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={() => {
                            inputFileRef.current.click();
                        }}>
                        SELECCIONAR ARCHIVO DE CARGUE
                    </Button>
                </Box>
            }

            <input type="file" name="file" ref={inputFileRef} onChange={onChangeHandler} className="hidenPanel" accept={typeFile ? typeFile:''}/>
            {formik && column &&
                <div className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained' style={{color: "#d32f2f", fontSize: "0.75rem", paddingLeft:"15px"}}>
                    {formik?.touched?.[column?.field] && formik?.errors?.[column?.field]}
                </div>
            }
        </>
    )
}