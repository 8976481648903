import React from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import CurrencyFormat from 'react-currency-format';
import SectionContainer from "../common/SectionContainer";
import { CustomPaper } from "../common/CustomPaper";
import { UnlabeledTextField } from "../common/UnlabeledTextField";
import RenderColumns from "../common/RenderColumns";
import { typeOfMixes } from "../../../../utils/constants";

export default function FooterSection({
    elements,
    show,
    totalQuantityFertilizer,
    getTotalCompositionByElements,
    totalPriceFertilizers,
    mixType,
    columnsWidth,
}) {
    return (
        <SectionContainer>
            <Grid
                item
                xs={show ? 3 : 8}
            >
                <TableContainer component={CustomPaper}>
                    <Table>
                        <TableBody>
                            <TableRow className="height-row">
                                <TableCell>
                                    <Typography>
                                        Recomendación final (kg) {totalQuantityFertilizer}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <CurrencyFormat
                                        renderText={(value) => (
                                            <Typography
                                                className="toRight"
                                                fontSize="1.3rem !important">
                                                <strong
                                                    style={{
                                                        color: (mixType?.id === typeOfMixes.byGrade && (totalQuantityFertilizer < 1000)) ? "var(--red-text)" : "inherit"
                                                    }}>
                                                    {value}
                                                </strong>
                                            </Typography>
                                        )}
                                        displayType="text"
                                        value={totalQuantityFertilizer}
                                        thousandSeparator
                                    />
                                </TableCell>
                            </TableRow>
                            {(mixType?.id === typeOfMixes.byGrade) && (
                                <>
                                    <TableRow className="height-row">
                                        <TableCell colSpan={2}>
                                            <Typography>
                                                Cumplimiento
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="height-row">
                                        <TableCell colSpan={2}>
                                            <Typography>
                                                Aporte (%)
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}

                            {(mixType?.id === typeOfMixes.byFertilizer) && (
                                <TableRow className="height-row">
                                    <TableCell>
                                        <Typography>
                                            Costo/tn Materia Prima
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyFormat
                                            renderText={(value) => (
                                                <Typography
                                                    className="toRight">
                                                    <strong>{value}</strong>
                                                </Typography>
                                            )}
                                            displayType="text"
                                            value={parseFloat(((totalPriceFertilizers / totalQuantityFertilizer) * 1000) || 0).toFixed(0)}
                                            thousandSeparator
                                            prefix="$ "
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {show && (
                <Grid
                    item
                    xs={7}>
                    <TableContainer component={CustomPaper}>
                        <Table className="table-min-width">
                            <TableBody>
                                <TableRow className="height-row">
                                    <RenderColumns
                                        elements={elements}
                                        Component={TotalCompositionByElement}
                                        getTotalCompositionByElements={getTotalCompositionByElements}
                                        columnsWidth={columnsWidth}
                                    />
                                </TableRow>
                                {(mixType?.id === typeOfMixes.byGrade) && (
                                    <>
                                        <TableRow className="height-row">
                                            <RenderColumns
                                                elements={elements}
                                                Component={ComplianceColumns}
                                                getTotalCompositionByElements={getTotalCompositionByElements}
                                                columnsWidth={columnsWidth}
                                            />
                                        </TableRow>

                                        <TableRow className="height-row">
                                            <RenderColumns
                                                elements={elements}
                                                Component={ContributionColumns}
                                                totalQuantityFertilizer={totalQuantityFertilizer}
                                                getTotalCompositionByElements={getTotalCompositionByElements}
                                                columnsWidth={columnsWidth}
                                            />
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            <Grid
                item
                xs={show ? 1 : 2}>
                <TableContainer component={CustomPaper}>
                    <Table>
                        <TableBody>
                            <TableRow className="height-row">
                                <TableCell
                                    className="textAlignCenter">
                                    <CurrencyFormat
                                        customInput={CustomCurrencyFormatTexfield}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={totalPriceFertilizers}
                                        prefix={`$ `}
                                        thousandSeparator
                                    />
                                </TableCell>
                                <TableCell className="textAlignCenter">
                                    
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </SectionContainer >
    );
}

function CustomCurrencyFormatTexfield({ ...props }) {
    return <UnlabeledTextField
        type="number"
        {...props}
        size="small"
        InputProps={{
            className: "mixesNotEditBtn",
        }}
    />
}

const TotalCompositionByElement = ({ element, getTotalCompositionByElements, width }) => {
    return (
        <TableCell
            sx={{
                width: width ?? "auto",
            }}
        >
            <UnlabeledTextField
                type="number"
                size="small"
                InputProps={{
                    readOnly: true,
                    className: "mixesNotEditBtn",
                }}
                value={parseFloat(getTotalCompositionByElements(element)).toFixed(0)}
            />
        </TableCell>
    );
}

// Fila cumplimiento
const ComplianceColumns = ({ element, getTotalCompositionByElements, width }) => {
    const getCompliance = () => {
        const elementValue = (element?.value || 0) * 10;

        if (!elementValue) return 0;

        const total = getTotalCompositionByElements(element) * 100;
        const result = total / elementValue;
        return isNaN(result) ? 0 : result;
    }

    const assignValidationColor = () => {
        let color = "rgba(0,0,0,0.23)";
        if (!element?.value) return color;

        const cumplimiento = getCompliance();

        if (cumplimiento > 99 && cumplimiento < 101) {
            color = "var(--bg-compliance-range-ok-btn) !important";
        } else {
            color = "var(--bg-compliance-range-not-ok-btn) !important";
        }

        return color;
    }

    return (
        <TableCell
            sx={{
                width: width ?? "auto",
            }}
        >
            <UnlabeledTextField
                type="number"
                size="small"
                InputProps={{
                    readOnly: true,
                    className: "mixesNotEditBtn",
                    sx: {
                        bgcolor: assignValidationColor(),
                    }
                }}
                sx={{
                    "& fieldset": {
                        borderColor: assignValidationColor(),
                    },
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: assignValidationColor(),
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: assignValidationColor(),
                        },
                    },
                }}
                value={parseFloat(getCompliance()).toFixed(0)}
            />
        </TableCell>
    );
}

// Fila aporte
const ContributionColumns = ({ element, totalQuantityFertilizer, getTotalCompositionByElements, width }) => {
    const getContributionPercentage = () => {
        let total = getTotalCompositionByElements(element) / totalQuantityFertilizer;
        if (isNaN(total)) return parseFloat(0).toFixed(3);
        if (total === 0) return parseFloat(total).toFixed(3);
        return `${parseFloat(total * 100).toFixed(3)}`;
    }
    return (
        <TableCell
            sx={{
                width: width ?? "auto",
            }}
        >
            <UnlabeledTextField
                type="number"
                size="small"
                InputProps={{
                    readOnly: true,
                    className: "mixesNotEditBtn",
                }}
                value={getContributionPercentage()}
            />
        </TableCell>
    );
}